import request from "@/utils/request";
const controller = "/VocationalEducation/InternshipScore";

const getPageList = params =>
  request({
    url: `${controller}/GetPageList`,
    method: "get",
    params
  });
const getAllList = params =>
  request({
    url: `${controller}/GetAllList`,
    method: "get",
    params
  });
const getInfo = params =>
  request({
    url: `${controller}/GetInfo`,
    method: "get",
    params
  });

const save = data =>
  request({
    url: `${controller}/Save`,
    method: "post",
    data
  });

export { getPageList, getAllList, getInfo, save };
