<template>
  <div class="page-content">
    <div class="content-box" v-loading="loading">
      <section-title v-if="isView" title="查看成绩" />
      <section-title
        v-else
        :title="
          type === '1'
            ? '实习成绩--学校指导老师填报'
            : '实习成绩--企业指导老师填报'
        "
      />
      <div v-loading="loading">
        <el-row class="u-flex">
          <div class="mr-8">学生姓名：</div>
          <div>{{ internshipInfo.StudentName }}</div>
        </el-row>
        <el-row class="u-flex">
          <div class="mr-8">实习时间：</div>
          <div>
            {{
              filterTimeStr(internshipInfo.StartTime, internshipInfo.EndTime)
            }}
          </div>
        </el-row>
        <el-row class="u-flex">
          <div class="mr-8">实习岗位：</div>
          <div>{{ internshipInfo.JobName }}</div>
        </el-row>
        <el-row class="u-flex">
          <div class="mr-8">实习状态：</div>
          <div>{{ internshipInfo.ImplementStatus_V }}</div>
        </el-row>
        <div v-if="isView">
          <el-row class="u-flex">
            <div class="mr-8">学校老师打分：</div>
            <div>
              {{
                internshipInfo.SchoolTeacherScore
                  ? internshipInfo.SchoolTeacherScore.Score
                  : ""
              }}
            </div>
          </el-row>
          <el-row class="u-flex">
            <div class="mr-8">学校老师评定：</div>
            <div>
              {{
                internshipInfo.SchoolTeacherScore
                  ? internshipInfo.SchoolTeacherScore.Name
                  : ""
              }}
            </div>
          </el-row>
          <el-row class="u-flex">
            <div class="mr-8">学校老师点评：</div>
            <div>
              {{
                internshipInfo.SchoolTeacherScore
                  ? internshipInfo.SchoolTeacherScore.Summary
                  : ""
              }}
            </div>
          </el-row>
          <el-row class="u-flex">
            <div class="mr-8">企业老师打分：</div>
            <div>
              {{
                internshipInfo.EnterpriseTeacherScore
                  ? internshipInfo.EnterpriseTeacherScore.Score
                  : ""
              }}
            </div>
          </el-row>
          <el-row class="u-flex">
            <div class="mr-8">企业老师评定：</div>
            <div>
              {{
                internshipInfo.EnterpriseTeacherScore
                  ? internshipInfo.EnterpriseTeacherScore.Name
                  : ""
              }}
            </div>
          </el-row>
          <el-row class="u-flex">
            <div class="mr-8">企业老师点评：</div>
            <div>
              {{
                internshipInfo.EnterpriseTeacherScore
                  ? internshipInfo.EnterpriseTeacherScore.Summary
                  : ""
              }}
            </div>
          </el-row>
        </div>
        <div v-else>
          <el-row class="u-flex">
            <div class="mr-8">实习成绩：</div>
            <el-input v-model="Score" type="number" class="u-flex-1"></el-input>
          </el-row>
          <el-row class="u-flex">
            <div class="mr-8">成绩评定：</div>
            <el-input
              v-model="Name"
              class="u-flex-1"
              maxlength="5"
              show-word-limit
            ></el-input>
          </el-row>
          <el-row>
            <div class="mr-8">实习点评：</div>
            <el-input
              v-model="Summary"
              type="textarea"
              resize="none"
              rows="5"
              class="u-flex-1"
            ></el-input>
          </el-row>
        </div>
      </div>
      <div class="form-footer">
        <el-button
          type="primary"
          v-if="!isView"
          @click="handleSave"
          :loading="btnLoading"
          >保存</el-button
        >
        <el-button type="default" @click="$router.go(-1)">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfo, save } from "@/api/internshipScore";
import SectionTitle from "@/components/SectionTitle.vue";
import contentTools from "@/mixins/content-tools";
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: false
    },
    implementId: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    }
  },
  components: {
    SectionTitle
  },
  mixins: [contentTools],
  data() {
    return {
      btnLoading: false,
      loading: false,
      internshipInfo: "",
      Score: "",
      Name: "",
      Summary: ""
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        await this.getInternshipInfo();
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
    async handleSave() {
      try {
        this.btnLoading = true;
        let params = {};
        if (this.type === "1" && this.internshipInfo.SchoolTeacherScore) {
          params.Id = this.internshipInfo.SchoolTeacherScore.Id;
        } else if (
          this.type === "2" &&
          this.internshipInfo.EnterpriseTeacherScore
        ) {
          params.Id = this.internshipInfo.EnterpriseTeacherScore.Id;
        } else {
          params.Id = null;
        }
        params.Name = this.Name;
        params.ImplementId = this.implementId;
        params.ScoreType = Number(this.type);
        params.Score = Number(this.Score);
        params.Summary = this.Summary;
        const res = await save(params);
        if (res.IsSuccess) {
          this.$message.success("打分成功");
          this.$router.go(-1);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.btnLoading = false;
      }
    },
    async getInternshipInfo() {
      let params = {};
      params.implementId = this.implementId;
      if (this.isEdit) {
        params.type = this.type;
      }
      const res = await getInfo(params);
      if (res.IsSuccess) {
        this.internshipInfo = res.Result;
        if (this.type === "1" && this.internshipInfo.SchoolTeacherScore) {
          this.Score = this.internshipInfo.SchoolTeacherScore.Score;
          this.Summary = this.internshipInfo.SchoolTeacherScore.Summary;
          this.Name = this.internshipInfo.SchoolTeacherScore.Name;
        } else if (
          this.type === "2" &&
          this.internshipInfo.EnterpriseTeacherScore
        ) {
          this.Score = this.internshipInfo.EnterpriseTeacherScore.Score;
          this.Summary = this.internshipInfo.EnterpriseTeacherScore.Summary;
          this.Name = this.internshipInfo.EnterpriseTeacherScore.Name;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content-box {
  width: 850px;
  margin: 0 auto;
  .el-row {
    display: flex;
    margin-bottom: 16px;
  }
}
</style>
